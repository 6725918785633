import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [(_openBlock(), _createBlock(_component_router_view, {
    key: $options.key
  }, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2
    }, 1024)]),
    _: 1
  }))]);
}