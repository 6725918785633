import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4322f104"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "album-cover__muilple"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  key: 1,
  class: "album-cover__single"
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "album-cover",
    style: _normalizeStyle({
      width: $props.width,
      height: $props.height
    })
  }, [$props.items && $props.items.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    class: "bottom img",
    src: $props.items[1][$props.urlKey]
  }, null, 8, _hoisted_2), _createElementVNode("img", {
    class: "top img",
    src: $props.items[0][$props.urlKey]
  }, null, 8, _hoisted_3)])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [$props.items && $props.items.length ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "img",
    src: $props.items[0][$props.urlKey]
  }, null, 8, _hoisted_5)) : _createCommentVNode("", true)]))], 4);
}