import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-53caebe5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "avatar-view"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("span", _hoisted_1, [_createElementVNode("span", null, [_createElementVNode("img", {
    class: "img",
    src: $props.src,
    style: _normalizeStyle($options.imgStyle)
  }, null, 12, _hoisted_2)]), $props.name ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.name), 1)) : _createCommentVNode("", true)]);
}