export default {
  emits: ['imgClick'],
  props: {
    align: {
      type: String,
      default: 'center'
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '32px'
    },
    url: String,
    objectFit: {
      type: String,
      default: 'cover'
    },
    link: Boolean
  },
  computed: {
    wrapperStyle() {
      const style = {
        display: 'flex',
        justifyContent: 'center'
      };

      if (this.align == 'left') {
        style.justifyContent = 'flex-start';
      } else if (this.align == 'right') {
        style.justifyContent = 'flex-end';
      }

      return style;
    },

    imageStyle() {
      return {
        width: this.width || 'auto',
        height: this.height || 'auto',
        objectFit: this.objectFit || 'center',
        cursor: this.link ? 'pointer' : 'normal'
      };
    },

    hasSlot() {
      return !!(this.$slots && this.$slots.default);
    }

  },

  setup(props, {
    emit
  }) {
    const handleImageClick = () => {
      emit('imgClick');
    };

    return {
      handleImageClick
    };
  }

};