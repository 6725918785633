import _ from 'lodash'

export function rangeArr(n) {
  return Array.from(Array.from({ length: n }).keys());
}

export function sortItem(items, oldIndex, newIndex) {
  if (oldIndex == newIndex) {
    return items
  }
  console.log({ oldIndex, newIndex })
  const arr = _.clone(items)
  const item = arr.splice(oldIndex, 1)[0]
  arr.splice(newIndex, 0, item)
  return arr
}

const WeekNames = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
export function getWeekName(day) {
  const arr = day.split('-')
  const dt = new Date(arr[0] - 0, arr[1] - 1, arr[2] - 0)
  return WeekNames[dt.getDay()]
}
