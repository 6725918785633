import { ElImageViewer } from 'element-plus'
import { createVNode, render } from 'vue'

let seed = 1

function ImageViewer(options, context) {
  const userOnClose = options && options.onClose

  let vm = null
  const elemId = `ImageViewer_${seed++}`;
  const props = {
    zIndex: 9999,
    ...options,
    onClose: () => {
      const elem = document.getElementById(elemId)
      document.body.removeChild(elem)
      userOnClose == null ? void 0 : userOnClose(vm);
    }
  }

  const container = document.createElement("div")
  vm = createVNode(ElImageViewer, props)

  vm.appContext = context || ImageViewer._context
  vm.props.onDestroy = () => {
    render(null, container)
    const elem = document.getElementById(elemId)
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
  }
  render(vm, container)

  container.firstElementChild.id = elemId
  document.body.appendChild(container.firstElementChild)
}

ImageViewer.install = (app) => {
  ImageViewer._context = app._context
}

export default ImageViewer
