import { mapGetters } from 'vuex';
import BreadcrumbView from './BreadcrumbView';
import { Fold, Expand } from '@element-plus/icons-vue';
import logoImg from '@/assets/logo.png';
import { title } from '@/config/app.js';
export default {
  components: {
    BreadcrumbView,
    Fold,
    Expand
  },

  data() {
    return {
      logo: logoImg,
      title: title,
      defaultAvatar: require('@/assets/imgs/avatar.png')
    };
  },

  computed: { ...mapGetters(['sidebar', 'avatar', 'name'])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar');
    },

    logout() {
      this.$store.dispatch('user/logout').then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },

    changePwd() {
      this.$router.push('/account/change-password');
    }

  }
};