import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sidebar_item = _resolveComponent("sidebar-item");

  const _component_el_menu = _resolveComponent("el-menu");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: "app-sidebar",
    "max-heigth": "100%"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_menu, {
      class: "menu",
      mode: "vertical",
      "show-timeout": 200,
      "default-active": $options.defaultActive,
      collapse: $options.isCollapse,
      "collapse-transition": false
    }, {
      default: _withCtx(() => [_createVNode(_component_sidebar_item, {
        routes: $options.permissionRoutes
      }, null, 8, ["routes"])]),
      _: 1
    }, 8, ["default-active", "collapse"])]),
    _: 1
  });
}