export default {
  created() {
    this.getBreadcrumb();
  },

  data() {
    return {
      levelList: null
    };
  },

  watch: {
    $route() {
      this.getBreadcrumb();
    }

  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name);

      if (matched[0].path === '/') {
        matched.shift();
      } // const first = matched[0]
      // if (first && first.name !== 'dashboard') {
      //   matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
      // }


      this.levelList = matched;
    }

  }
};