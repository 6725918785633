import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-05577cea"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "file-uploader"
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_progress = _resolveComponent("el-progress");

  const _component_el_upload = _resolveComponent("el-upload");

  const _component_el_input = _resolveComponent("el-input");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
    modelValue: $options.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event)
  }, {
    append: _withCtx(() => [_createVNode(_component_el_upload, {
      ref: "upload",
      class: "uploader",
      action: $setup.uploadUrl,
      data: $setup.uploadData,
      "show-file-list": false,
      "on-success": $setup.handleSuccess,
      "before-upload": $setup.beforeUpload,
      limit: 1,
      "on-exceed": $setup.handleExceed,
      "on-progress": $setup.handleProgress,
      "on-error": $setup.handleError,
      "auto-upload": true,
      accept: $props.accept
    }, {
      default: _withCtx(() => [$setup.isUploading ? (_openBlock(), _createBlock(_component_el_progress, {
        key: 0,
        width: 30,
        "stroke-width": 3,
        "show-text": false,
        type: "circle",
        percentage: $setup.uploadPercentage
      }, null, 8, ["percentage"])) : (_openBlock(), _createElementBlock("span", _hoisted_2, "上传"))]),
      _: 1
    }, 8, ["action", "data", "on-success", "before-upload", "on-exceed", "on-progress", "on-error", "accept"])]),
    _: 1
  }, 8, ["modelValue"])]);
}