import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navbar_view = _resolveComponent("navbar-view");

  const _component_el_header = _resolveComponent("el-header");

  const _component_sidebar_view = _resolveComponent("sidebar-view");

  const _component_el_aside = _resolveComponent("el-aside");

  const _component_app_main_view = _resolveComponent("app-main-view");

  const _component_el_main = _resolveComponent("el-main");

  const _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createBlock(_component_el_container, {
    class: _normalizeClass(["app-wrapper", $options.classObj])
  }, {
    default: _withCtx(() => [_createVNode(_component_el_header, {
      class: "app-header"
    }, {
      default: _withCtx(() => [_createVNode(_component_navbar_view)]),
      _: 1
    }), _createVNode(_component_el_container, {
      class: "app-container"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_aside, null, {
        default: _withCtx(() => [_createVNode(_component_sidebar_view)]),
        _: 1
      }), _createVNode(_component_el_main, null, {
        default: _withCtx(() => [_createVNode(_component_app_main_view)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["class"]);
}