import { NavbarView, SidebarView, AppMainView } from './components';
import ResizeMixin from './mixin/ResizeHandler';
export default {
  name: 'LayoutView',
  components: {
    NavbarView,
    SidebarView,
    AppMainView
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },

    device() {
      return this.$store.state.app.device;
    },

    classObj() {
      return {
        'hide-sidebar': !this.sidebar.opened,
        'without-animation': this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }

  }
};