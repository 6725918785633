import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission' // permission control

import './styles/index.scss'
import './styles/theme/default.scss'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import SvgIcon from '@/plugins/SvgIcon.js'
import ImageViewer from '@/plugins/ImageViewer.js'

import SearchView from '@/components/PageLayout/SearchView.vue'
import AvatarView from '@/components/PictureView/AvatarView.vue'
import CellImage from '@/components/PictureView/CellImage.vue'
import AlbumCover from '@/components/PictureView/AlbumCover.vue'
import FileUploader from '@/components/Uploader/FileUploader.vue'
import ImageUploader from '@/components/Uploader/ImageUploader.vue'
import ImageWallUploader from '@/components/Uploader/ImageWallUploader.vue'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App)

app.use(store)
.use(router)
.use(SvgIcon)
.use(ImageViewer);

app.component(SearchView);
app.component(AvatarView);
app.component(CellImage);
app.component(AlbumCover);
app.component(FileUploader);
app.component(ImageUploader);
app.component(ImageWallUploader);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, {
  locale: zhCn,
  size: 'default'
})

app.mount('#app')
