import { withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-44ffde9a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "cell-image__inner"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("span", {
    class: "cell-image",
    style: _normalizeStyle($options.wrapperStyle)
  }, [_createElementVNode("span", _hoisted_1, [!$options.hasSlot ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $props.url,
    style: _normalizeStyle($options.imageStyle),
    onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => $setup.handleImageClick && $setup.handleImageClick(...args), ["stop"]))
  }, null, 12, _hoisted_2)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 4);
}