import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutView from '../views/layout/LayoutView'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { ElMessage, ElNotification } from 'element-plus'

export const constantRoutes = [{
  name: 'login',
  path: '/login',
  component: () => import('@/views/login/LoginView.vue'),
  meta: {
    title: '登录'
  },
  hidden: true
}, {
  name: '404',
  path: '/404',
  component: () => import('@/views/error-page/Page404.vue'),
  hidden: true,
}]

export const asyncRoutes = [{
    name: 'home',
    path: '/',
    component: LayoutView,
    redirect: '/dashboard',
    meta: {
      title: '首页',
      icon: ElementPlusIconsVue.HomeFilled,
    },
    children: [{
      name: 'dashboard',
      path: '/dashboard',
      component: () => import('@/views/dashboard/DefaultView.vue'),
      meta: {
        title: '首页',
        icon: ElementPlusIconsVue.HomeFilled,
      },
      hidden: true
    }]
  }, {
    name: 'book',
    path: '/book',
    component: LayoutView,
    meta: {
      'title': '预订管理',
      svgIcon: 'book'
    },
    redirect: 'noredirect',
    children: [{
      name: 'book-index',
      path: '/book/index',
      component: () => import('@/views/book/SummaryView.vue'),
      meta: {
        title: '预订统计',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['bookorder']
      },
    }, {
      name: 'book-finance',
      path: '/book/finance',
      component: () => import('@/views/book/FinanceView.vue'),
      meta: {
        title: '财务统计',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['bookorder']
      },
    }, {
      name: 'book-order',
      path: '/book/order',
      component: () => import('@/views/book/OrderView.vue'),
      meta: {
        title: '订单列表',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['bookorder']
      },
    }, {
      name: 'book-food',
      path: '/book/food',
      component: () => import('@/views/book/FoodView.vue'),
      meta: {
        title: '菜品管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['bookfood']
      },
    }, {
      name: 'book-period',
      path: '/book/period',
      component: () => import('@/views/book/PeriodView.vue'),
      meta: {
        title: '时段管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['bookperiod']
      },
    }]
  }, {
    name: 'menu',
    path: '/menu',
    component: LayoutView,
    meta: {
      'title': '菜单管理',
      svgIcon: 'menu'
    },
    redirect: 'noredirect',
    children: [{
      name: 'menu-index',
      path: '/menu/index',
      component: () => import('@/views/menu/MenuView.vue'),
      meta: {
        title: '菜单设置',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['menu']
      },
    }, {
      name: 'menu-food',
      path: '/menu/food',
      component: () => import('@/views/menu/FoodView.vue'),
      meta: {
        title: '菜品管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['food']
      },
    }, {
      name: 'purchase-item',
      path: '/purchase/item',
      component: () => import('@/views/purchase/ItemView.vue'),
      meta: {
        title: '采购公示',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['purchase']
      },
    }]
  }, {
    name: 'duty',
    path: '/duty',
    component: LayoutView,
    meta: {
      'title': '值班管理',
      svgIcon: 'duty'
    },
    redirect: 'noredirect',
    children: [{
      name: 'duty-schedule',
      path: '/duty/schedule',
      component: () => import('@/views/duty/ScheduleView.vue'),
      meta: {
        title: '值班设置',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['dutyschedule']
      },
    }, {
      name: 'duty-person',
      path: '/duty/person',
      component: () => import('@/views/duty/PersonView.vue'),
      meta: {
        title: '值班人员',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['dutyperson']
      },
    }]
  }, {
    name: 'content',
    path: '/content',
    component: LayoutView,
    meta: {
      'title': '内容管理',
      svgIcon: 'video'
    },
    redirect: 'noredirect',
    children: [{
      name: 'content-sequence',
      path: '/content/sequence',
      component: () => import('@/views/content/SequenceView.vue'),
      meta: {
        title: '播放列表',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['sequence']
      },
    }, {
      name: 'content-notice',
      path: '/content/notice',
      component: () => import('@/views/content/NoticeView.vue'),
      meta: {
        title: '公告管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['notice']
      },
    }]
  }, {
    name: 'material',
    path: '/material',
    component: LayoutView,
    meta: {
      'title': '素材管理',
      svgIcon: 'album'
    },
    redirect: 'noredirect',
    children: [{
      name: 'material-album',
      path: '/material/album',
      component: () => import('@/views/material/AlbumView.vue'),
      meta: {
        title: '图集管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['album']
      },
    }, {

      name: 'material-video',
      path: '/material/video',
      component: () => import('@/views/material/VideoView.vue'),
      meta: {
        title: '视频管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['video']
      },
    }]
  }, {
    name: 'device',
    path: '/device',
    component: LayoutView,
    meta: {
      'title': '设备管理',
      svgIcon: 'camera'
    },
    redirect: 'noredirect',
    children: [{
      name: 'device-terminal',
      path: '/device/terminal',
      component: () => import('@/views/device/TerminalView.vue'),
      meta: {
        title: '终端管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['terminal']
      },
    }, {

      name: 'device-camera',
      path: '/device/camera',
      component: () => import('@/views/device/CameraView.vue'),
      meta: {
        title: '监控管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['camera']
      },
    }]
  }, {
    name: 'member',
    path: '/member',
    component: LayoutView,
    meta: {
      'title': '人员管理',
      svgIcon: 'member'
    },
    redirect: 'noredirect',
    children: [{
      name: 'member-employee',
      path: '/member/employee',
      component: () => import('@/views/member/EmployeeView.vue'),
      meta: {
        title: '员工管理',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['employee']
      },
    }]
  }, {
    name: 'permission',
    path: '/permission',
    component: LayoutView,
    meta: {
      'title': '权限设置',
      icon: ElementPlusIconsVue.Avatar
    },
    redirect: 'noredirect',
    children: [{
      name: 'permission-adminuser',
      path: '/permission/adminuser',
      component: () => import('@/views/permission/AdminUserView.vue'),
      meta: {
        title: '账号列表',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['adminuser']
      },
    }, {
      name: 'permission-role',
      path: '/permission/role',
      component: () => import('@/views/permission/RoleView.vue'),
      meta: {
        title: '角色列表',
        icon: ElementPlusIconsVue.Tickets,
        permissions: ['role']
      },
    }]
  }, {
    name: 'account',
    path: '/account',
    component: LayoutView,
    hidden: true,
    meta: {
      'title': '个人中心',
      icon: ElementPlusIconsVue.Avatar
    },
    redirect: 'noredirect',
    children: [{
      name: 'account-changepassword',
      path: '/account/change-password',
      component: () => import('@/views/account/ChangePasswordView.vue'),
      meta: {
        title: '修改密码',
        icon: ElementPlusIconsVue.Tickets
      },
    }]
  },

  // 404 page must be placed at the end !!!
  { path: '/:pathMatch(.*)', redirect: '/404', hidden: true }
]

function createRouterInstance(routes) {
  const routerInstance = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({ y: 0 }),
    routes
  })
  routerInstance.beforeEach(() => {
    ElMessage.closeAll()
    ElNotification.closeAll()
  })

  return routerInstance
}

const router = createRouterInstance(constantRoutes)

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouterInstance(constantRoutes)
  router.matcher = newRouter.matcher // reset router
}

function getRoutesByPermission(routeArr, permissionMap) {
  if (!routeArr) {
    return routeArr
  }
  const arr = []
  for (let i = 0; i < routeArr.length; i++) {
    if (routeArr[i].meta && routeArr[i].meta.permissions) {
      let hasRole = false
      for (let j = 0; j < routeArr[i].meta.permissions.length; j++) {
        if (permissionMap[routeArr[i].meta.permissions[j]]) {
          hasRole = true
          break
        }
      }
      if (!hasRole) {
        continue
      }
    }
    if (routeArr[i].children && routeArr[i].children.length) {
      const children = getRoutesByPermission(routeArr[i].children, permissionMap)
      if (children && children.length) {
        routeArr[i].children = children
        arr.push(routeArr[i])
      }
    } else {
      arr.push(routeArr[i])
    }
  }
  return arr
}

export const allRoutes = constantRoutes.concat(asyncRoutes)

export function filterRoutesByPermisison(routes, permissions) {
  permissions = permissions || []
  const permissionMap = {}
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i]) {
      permissionMap[permissions[i]] = true
    }
  }
  return getRoutesByPermission(routes, permissionMap)
}

export default router
