export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isExternal() {
      return /^http(s?):\/\//i.test(this.to);
    },

    type() {
      if (!this.to) {
        return 'span';
      }

      if (this.isExternal) {
        return 'a';
      }

      return 'router-link';
    }

  },
  methods: {
    linkProps(to) {
      if (this.isExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        };
      }

      return {
        to: to
      };
    }

  }
};