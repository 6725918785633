import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

axios.defaults.withCredentials = false
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      config.headers['X-Token-Admin'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.data && response.data.code !== 0) {
      ElMessage({
        message: response.data.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      const error = new Error(response.data.message || 'Error');
      error.code = response.data.code

      return Promise.reject(error)
    } else {
      return Promise.resolve(response.data)
    }
  },
  error => {
    console.log(error)
    if (error.name === 'AxiosError' || error.constructor.name === 'AxiosError') {
      if (error.code === 'ERR_NETWORK') {
        ElMessage({
          message: '连接服务器失败，请稍后重试！',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(error)
      } else if (error.response) {
        if (error.response.status === 401) {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
          return Promise.reject(null)
        }
        if (error.response.status === 403) {
          ElMessage({
            message: '非法请求！',
            type: 'error',
            duration: 5 * 1000
          })
          return Promise.reject(new Error('非法请求'))
        }
        if (error.response.status === 422) { // 输入验证错误
          ElMessage({
            message: error.response.data.message,
            type: 'error',
            duration: 5 * 1000
          })
          return Promise.reject(new Error(error.response.data.message))
        }
      }

      ElMessage({
        message: '服务器开小猜了！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service
