import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-38ba3688"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "image-wall-uploader"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "el-upload-list__item-actions"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_zoom_in = _resolveComponent("zoom-in");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_Delete = _resolveComponent("Delete");

  const _component_el_input = _resolveComponent("el-input");

  const _component_Plus = _resolveComponent("Plus");

  const _component_el_upload = _resolveComponent("el-upload");

  const _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_upload, {
    ref: "uploader",
    "file-list": $options.value,
    "onUpdate:file-list": _cache[1] || (_cache[1] = $event => $options.value = $event),
    "list-type": "picture-card",
    action: $setup.uploadUrl,
    data: $setup.uploadData,
    "on-success": $setup.handleSuccess,
    "before-upload": $setup.beforeUpload,
    "on-error": $setup.handleError,
    accept: $props.accept,
    multiple: $props.multiple
  }, {
    file: _withCtx(({
      file
    }) => [_withDirectives((_openBlock(), _createElementBlock("div", {
      style: _normalizeStyle({
        width: $props.imageWidth,
        height: $props.imageHeight,
        position: 'relative'
      })
    }, [_createElementVNode("img", {
      class: "el-upload-list__item-thumbnail",
      src: file[$props.imageUrlKey] || file.url,
      alt: ""
    }, null, 8, _hoisted_2), _createElementVNode("span", _hoisted_3, [_createElementVNode("span", {
      class: "el-upload-list__item-preview",
      onClick: $event => $setup.handlePreview(file)
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_zoom_in)]),
      _: 1
    })], 8, _hoisted_4), _createElementVNode("span", {
      class: "el-upload-list__item-delete",
      onClick: $event => $options.handleRemove(file)
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_Delete)]),
      _: 1
    })], 8, _hoisted_5)]), $props.titleKey ? (_openBlock(), _createBlock(_component_el_input, {
      key: 0,
      size: "small",
      class: "el-upload-list__item-title",
      modelValue: file[$props.titleKey],
      "onUpdate:modelValue": $event => file[$props.titleKey] = $event,
      placeholder: "请输入标题",
      onKeydown: _cache[0] || (_cache[0] = _withModifiers(() => {
        return true;
      }, ["stop"]))
    }, null, 8, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true)], 4)), [[_directive_loading, file.url && /^blob:/i.test(file.url)]])]),
    default: _withCtx(() => [_createElementVNode("div", {
      style: _normalizeStyle({
        width: $props.imageWidth,
        height: $props.imageHeight,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      })
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_Plus)]),
      _: 1
    })], 4)]),
    _: 1
  }, 8, ["file-list", "action", "data", "on-success", "before-upload", "on-error", "accept", "multiple"])]);
}