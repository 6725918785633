import "core-js/modules/es.array.includes.js";
import { getToken } from '@/api/qiniu.js';
import { ref, onMounted } from 'vue';
import { ElMessage, genFileId } from 'element-plus';
export default {
  emits: ['update:modelValue', 'success'],
  props: {
    customClass: String,
    modelValue: String,
    accept: {
      type: String,
      default: 'video/mp4, *.mp4'
    },
    maxFileSize: {
      type: Number,
      default: 200 * 1024 * 1024
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      }

    }
  },

  setup(props, {
    emit
  }) {
    const uploadUrl = ref('');
    const uploadData = ref({});
    const upload = ref(null);
    const isUploading = ref(false);
    const uploadPercentage = ref(0);
    let domain = '';

    const handleSuccess = (response, file) => {
      isUploading.value = false;
      const url = domain.replace(/[/]+$/, '') + '/' + response.key;
      emit('update:modelValue', url);
      var m = file.name.match(/^(.+)\.([^.]+)/);
      file['localFileName'] = m && m[1] || file.name;
      file['localFileExt'] = m && m[2] || '';
      emit('success', file);
    };

    const beforeUpload = rawFile => {
      if (!props.accept.split(',').includes(rawFile.type)) {
        ElMessage.error('不支持的文件格式: ' + rawFile.type);
        return false;
      } else if (rawFile.size > props.maxFileSize) {
        const size = props.maxFileSize > 1024 * 1024 ? (props.maxFileSize / 1024 / 1024).toFixed(0) + 'MB' : props.maxFileSize > 1024 ? (props.maxFileSize / 1024).toFixed(0) + 'KB' : props.maxFileSize + 'B';
        ElMessage.error('文件大小不能超过' + size);
        return false;
      }

      return true;
    };

    const handleExceed = files => {
      upload.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      upload.value.handleStart(file);
    };

    const handleProgress = ({
      percent
    }) => {
      if (!isUploading.value) {
        isUploading.value = true;
      }

      uploadPercentage.value = percent.toFixed(0) - 0;
    };

    const handleError = err => {
      isUploading.value = false;
      ElMessage.error(err.message);
    };

    onMounted(() => {
      getToken().then(({
        data
      }) => {
        domain = data.domain;
        uploadUrl.value = data.upload_url;
        uploadData.value = {
          token: data.token
        };
      }).catch(err => {
        console.log(err);
      });
    });
    return {
      uploadUrl,
      uploadData,
      beforeUpload,
      handleSuccess,
      handleExceed,
      upload,
      isUploading,
      uploadPercentage,
      handleProgress,
      handleError
    };
  }

};