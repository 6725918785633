import LinkItem from './LinkItem.vue';
export default {
  components: {
    LinkItem
  },
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showingChildren(children) {
      if (!children) {
        return 0;
      }

      const showingChildren = children.filter(item => {
        return !item.hidden;
      });
      return showingChildren.length;
    }

  }
};