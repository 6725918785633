import "core-js/modules/es.array.includes.js";
import { ref, onMounted } from 'vue';
import { Plus, ZoomIn, Delete } from '@element-plus/icons-vue';
import { getToken } from '@/api/qiniu.js';
import { ElMessage } from 'element-plus';
import Sortable from 'sortablejs';
import { sortItem } from '@/utils/index.js';
export default {
  components: {
    Plus,
    ZoomIn,
    Delete
  },
  emits: ['preview', 'update:modelValue'],
  props: {
    titleKey: String,
    imageUrlKey: {
      type: String,
      default: 'url'
    },
    modelValue: {
      type: Array,

      default() {
        return [];
      }

    },
    accept: {
      type: String,
      default: 'image/jpeg,image/jpg,image/png,image/gif'
    },
    maxFileSize: {
      type: Number,
      default: 10 * 1024 * 1024
    },
    sortable: Boolean,
    multiple: Boolean,
    imageWidth: {
      type: String,
      default: '148px'
    },
    imageHeight: {
      type: String,
      default: '148px'
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      }

    }
  },

  setup(props, {
    emit
  }) {
    const uploadUrl = ref('');
    const uploadData = ref({});
    const uploader = ref();
    let domain = '';

    const handlePreview = file => {
      emit('preview', file);
    };

    const handleSuccess = (response, file) => {
      const url = domain.replace(/[/]+$/, '') + '/' + response.key;
      file[props.imageUrlKey] = file.url = url;
      var m = file.name.match(/^(.+)\.([^.]+)/);
      file['localFileName'] = m && m[1] || file.name;
      file['localFileExt'] = m && m[2] || ''; //emit('update:modelValue')
    };

    const beforeUpload = rawFile => {
      if (!props.accept.split(',').includes(rawFile.type)) {
        ElMessage.error('不支持的文件格式: ' + rawFile.type);
        return false;
      } else if (rawFile.size > props.maxFileSize) {
        const size = props.maxFileSize > 1024 * 1024 ? (props.maxFileSize / 1024 / 1024).toFixed(0) + 'MB' : props.maxFileSize > 1024 ? (props.maxFileSize / 1024).toFixed(0) + 'KB' : props.maxFileSize + 'B';
        ElMessage.error('文件大小不能超过' + size);
        return false;
      }

      return true;
    };

    const handleError = err => {
      ElMessage.error(err.message);
    };

    onMounted(() => {
      getToken().then(({
        data
      }) => {
        domain = data.domain;
        uploadUrl.value = data.upload_url;
        uploadData.value = {
          token: data.token
        };
      }).catch(err => {
        console.log(err);
      });

      if (props.sortable) {
        const el = uploader.value.$el.querySelector('.el-upload-list');
        Sortable.create(el, {
          direction: 'horizontal',
          onEnd: ({
            oldIndex,
            newIndex
          }) => {
            emit('update:modelValue', sortItem(props.modelValue, oldIndex, newIndex));
          }
        });
      }
    });
    return {
      uploader,
      uploadUrl,
      uploadData,
      handleSuccess,
      beforeUpload,
      handleError,
      handlePreview
    };
  },

  methods: {
    handleRemove(file) {
      const fileList = this.value;

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i] === file) {
          fileList.splice(i, 1);
          break;
        }
      }

      this.value = fileList;
    }

  }
};