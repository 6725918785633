import SvgIconView from '@/components/SvgIcon/SvgIcon.vue' // svg component

const SvgIcon = {
  install(app) {
    app.component('svg-icon', SvgIconView)

    // 导入SvgIcon图标
    const req = require.context('@/icons/svg', false, /\.svg$/)
    req.keys().map(req)
  }
}

export default SvgIcon
