export default {
  computed: {
    showSearch() {
      return !!this.$slots.search;
    },

    showFooter() {
      return !!this.$slots.footer;
    }

  }
};