import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-34ef07b2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "no-redirect"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  return _openBlock(), _createBlock(_component_el_breadcrumb, {
    class: "app-breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_TransitionGroup, {
      name: "breadcrumb"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.levelList, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: item.path
        }, [item.meta.title ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
          key: 0
        }, {
          default: _withCtx(() => [item.redirect === 'noredirect' || index == $data.levelList.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.meta.title), 1)) : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: item.redirect || item.path
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.meta.title), 1)]),
            _: 2
          }, 1032, ["to"]))]),
          _: 2
        }, 1024)) : _createCommentVNode("", true)], 64);
      }), 128))]),
      _: 1
    })]),
    _: 1
  });
}