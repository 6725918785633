export default {
  props: {
    size: {
      type: String,
      default: '40px'
    },
    src: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    viewStyle() {
      return {
        height: this.size || '40px'
      };
    },

    imgStyle() {
      const s = this.size || '40px';
      return {
        height: s,
        width: s,
        borderRadius: this.round === false ? 0 : '100%'
      };
    }

  }
};