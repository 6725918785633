import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_menu_item = _resolveComponent("el-menu-item");

  const _component_LinkItem = _resolveComponent("LinkItem");

  const _component_sidebar_item = _resolveComponent("sidebar-item", true);

  const _component_el_sub_menu = _resolveComponent("el-sub-menu");

  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.routes, item => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: item.name
    }, [!item.hidden ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [!$options.showingChildren(item.children) ? (_openBlock(), _createBlock(_component_LinkItem, {
      key: 0,
      to: item.path
    }, {
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: item.path || item.name
      }, {
        title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.meta && item.meta.title ? item.meta.title : item.name), 1)]),
        default: _withCtx(() => [item.meta && (item.meta.icon || item.meta.svgIcon) ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0,
          size: 16
        }, {
          default: _withCtx(() => [item.meta.svgIcon ? (_openBlock(), _createBlock(_component_svg_icon, {
            key: 0,
            icon: item.meta.svgIcon
          }, null, 8, ["icon"])) : (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon), {
            key: 1
          }))]),
          _: 2
        }, 1024)) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["index"])]),
      _: 2
    }, 1032, ["to"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
      key: 1,
      index: item.path || item.name
    }, {
      title: _withCtx(() => [item.meta && (item.meta.icon || item.meta.svgIcon) ? (_openBlock(), _createBlock(_component_el_icon, {
        key: 0,
        size: 16
      }, {
        default: _withCtx(() => [item.meta.svgIcon ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          icon: item.meta.svgIcon
        }, null, 8, ["icon"])) : (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon), {
          key: 1
        }))]),
        _: 2
      }, 1024)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(item.meta && item.meta.title ? item.meta.title : item.name), 1)]),
      default: _withCtx(() => [_createVNode(_component_sidebar_item, {
        routes: item.children
      }, null, 8, ["routes"])]),
      _: 2
    }, 1032, ["index"]))], 64)) : _createCommentVNode("", true)], 64);
  }), 128);
}