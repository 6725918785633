import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-ad1b2aa6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "search_page"
};
const _hoisted_2 = {
  key: 0,
  class: "search_page__search"
};
const _hoisted_3 = {
  class: "search_page__main"
};
const _hoisted_4 = {
  key: 1,
  class: "search_page__footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.showSearch ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "search", {}, undefined, true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), $options.showFooter ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "footer", {}, undefined, true)])) : _createCommentVNode("", true)]);
}