import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import logoImg from '@/assets/logo.png';
import { title } from '@/config/app.js';
import { allRoutes, filterRoutesByPermisison } from '@/router';
export default {
  components: {
    SidebarItem
  },

  data() {
    return {
      logo: logoImg,
      title: title
    };
  },

  computed: { ...mapGetters(['sidebar', 'permissions']),

    permissionRoutes() {
      return filterRoutesByPermisison(allRoutes, this.permissions);
    },

    isCollapse() {
      return !this.sidebar.opened;
    },

    defaultActive() {
      return this.$route.path.replace('/dashboard', '/');
    }

  }
};