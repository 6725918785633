import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-16d1c0ea"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Plus = _resolveComponent("Plus");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_upload = _resolveComponent("el-upload");

  return _openBlock(), _createElementBlock("div", {
    class: "image-uploader",
    style: _normalizeStyle({
      width: $props.width,
      height: $props.height
    })
  }, [$setup.uploadUrl ? (_openBlock(), _createBlock(_component_el_upload, {
    key: 0,
    class: "uploader",
    "show-file-list": false,
    action: $setup.uploadUrl,
    data: $setup.uploadData,
    "on-success": $setup.handleSuccess,
    "before-upload": $setup.beforeUpload,
    "on-error": $setup.handleError,
    "on-preview": $setup.handlePreview,
    accept: $props.accept
  }, {
    default: _withCtx(() => [$setup.imageUrl || $options.value ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: $setup.imageUrl || $options.value,
      style: {
        "width": "100",
        "height": "100%"
      }
    }, null, 8, _hoisted_1)) : (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      class: "uploader-icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_Plus)]),
      _: 1
    }))]),
    _: 1
  }, 8, ["action", "data", "on-success", "before-upload", "on-error", "on-preview", "accept"])) : _createCommentVNode("", true)], 4);
}