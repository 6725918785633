import { computed } from "@vue/reactivity";
export default {
  props: {
    icon: {
      type: String
    }
  },

  setup(props) {
    const iconName = computed(() => {
      return props.icon ? `#icon-${props.icon}` : "#icon";
    });
    const svgClass = computed(() => {
      return props.icon ? "svg-icon " + props.icon : "svg-icon";
    });
    return {
      iconName,
      svgClass
    };
  }

};