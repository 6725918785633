import request from '@/utils/request'

export function login({ username, password }) {
  return request({
    url: '/login',
    method: 'post',
    data: { username, password }
  })
}

export function getInfo() {
  return request({
    url: '/auth/info',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function changePassword({ oldPassword, newPassword }) {
  return request({
    url: '/auth/change-password',
    method: 'post',
    data: { oldPassword, newPassword }
  })
}

export function getWechatBindQrCode() {
  return request({
    url: '/auth/wechat-bind-qrcode',
    method: 'get',
  })
}

export function refreshWechatBind({ ticket }) {
  return request({
    url: '/auth/wechat-bind-result',
    method: 'get',
    params: { ticket }
  })
}

export function unbindWechat() {
  return request({
    url: '/auth/wechat-unbind',
    method: 'post'
  })
}

export function getWechatLoginQrCode() {
  return request({
    url: '/login/wechat-qrcode',
    method: 'get',
  })
}

export function getTokenByTicket({ ticket }) {
  return request({
    url: '/login/token-by-ticket',
    method: 'get',
    params: { ticket }
  })
}
