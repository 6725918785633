export default {
  props: {
    items: {
      type: Array,

      default() {
        return [];
      }

    },
    urlKey: {
      type: String,
      default: 'cover'
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '32px'
    }
  },

  setup() {}

};