import "core-js/modules/es.array.includes.js";
import { getToken } from '@/api/qiniu.js';
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
export default {
  emits: ['update:modelValue', 'preview', 'success'],
  components: {
    Plus
  },
  props: {
    width: {
      type: String,
      default: '160px'
    },
    height: {
      type: String,
      default: '160px'
    },
    customClass: String,
    modelValue: String,
    accept: {
      type: String,
      default: 'image/jpeg,image/jpg,image/png,image/gif'
    },
    maxFileSize: {
      type: Number,
      default: 10 * 1024 * 1024
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      }

    }
  },

  setup(props, {
    emit
  }) {
    const imageUrl = ref('');
    const uploadUrl = ref('');
    const uploadData = ref({});
    let domain = '';
    imageUrl.value = props.value || '';

    const handlePreview = file => {
      emit('preview', file);
    };

    const handleSuccess = (response, file) => {
      const url = domain.replace(/[/]+$/, '') + '/' + response.key;
      imageUrl.value = url;
      file.url = url;
      emit('update:modelValue', url);
      var m = file.name.match(/^(.+)\.([^.]+)/);
      file['localFileName'] = m && m[1] || file.name;
      file['localFileExt'] = m && m[2] || '';
      emit('success', file);
    };

    const beforeUpload = rawFile => {
      if (!props.accept.split(',').includes(rawFile.type)) {
        ElMessage.error('不支持的文件格式: ' + rawFile.type);
        return false;
      } else if (rawFile.size > props.maxFileSize) {
        const size = props.maxFileSize > 1024 * 1024 ? (props.maxFileSize / 1024 / 1024).toFixed(0) + 'MB' : props.maxFileSize > 1024 ? (props.maxFileSize / 1024).toFixed(0) + 'KB' : props.maxFileSize + 'B';
        ElMessage.error('文件大小不能超过' + size);
        return false;
      }

      return true;
    };

    const handleError = err => {
      ElMessage.error(err.message);
    };

    onMounted(() => {
      getToken().then(({
        data
      }) => {
        domain = data.domain;
        uploadUrl.value = data.upload_url;
        uploadData.value = {
          token: data.token
        };
      }).catch(err => {
        console.log(err);
      });
    });
    return {
      imageUrl,
      uploadUrl,
      uploadData,
      beforeUpload,
      handleSuccess,
      handleError,
      handlePreview
    };
  }

};